import { render, staticRenderFns } from "./InfoPage.vue?vue&type=template&id=00e76f0c&scoped=true&"
import script from "./InfoPage.vue?vue&type=script&lang=ts&"
export * from "./InfoPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00e76f0c",
  null
  
)

export default component.exports