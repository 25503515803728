









import { Component, Vue, Prop } from 'vue-property-decorator';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import BoxPage from '@/themes/v1/components/BoxPage.vue';
@Component({
  components: { BoxContent, BoxPage },
})
export default class InfoPage extends Vue {
  @Prop({ type: String, required: false })
  title!: string;

  @Prop({ type: String, required: true })
  text!: string;
}
